import React, { useLayoutEffect, useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import Navbar from "../components/Navbar";
import herobg from "../assets/images/bg1.png";
import secbg from "../assets/images/bg2.png";
import secbgphone from "../assets/images/phone-hero.png";
import secimgphone from "../assets/images/phone-bg.png";
import icon1 from "../assets/images/ico1.png";
import brandingicon from "../assets/images/branding.png";
import strategicicon from "../assets/images/strategic.png";
import globeicon from "../assets/images/globe.png";
import tokenicon from "../assets/images/token.png";
import commicon from "../assets/images/community.png";
import listingicon from "../assets/images/listing.png";
import projecticon from "../assets/images/project.png";
import phoneicon from "../assets/images/phone.png";
import StickyBox from "../components/StickyBox";
import TitleAnimation from "../components/TitleAnimation";
import Marquee from "../components/Marquee";
import Footer from "../components/Footer";
import bag from "../assets/images/bag.png";

export default function Scroll() {
  gsap.registerPlugin(ScrollTrigger);

  const heroRef = useRef(null);
  const teamRef = useRef(null);
  const rightColRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const swiperRef = useRef(null); // Reference to the Swiper instance

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.slidePrev) {
      swiperRef.current.slidePrev(); // Navigate to the previous slide
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.slideNext) {
      swiperRef.current.slideNext(); // Navigate to the next slide
    }
  };

  const titleRef2 = useRef(null);
  const main = useRef();
  // Function to split the text into individual characters
  const splitText = () => {
    const text = titleRef2.current.innerText;
    titleRef2.current.innerHTML = ""; // Clear the original text

    // Split the text into individual characters and wrap each in a span
    text.split("").forEach((char) => {
      const span = document.createElement("span");
      span.classList.add("char");
      span.innerText = char;
      titleRef2.current.appendChild(span);
    });
  };

  useEffect(() => {
    // Split the text into characters on mount
    splitText();

    // Trigger GSAP animation on scroll using ScrollTrigger
    if (titleRef2.current) {
      gsap.from(".fadein .char", {
        duration: 1,
        opacity: 0,
        stagger: 0.02,
        y: 30,
        ease: "power1.out",
        scrollTrigger: {
          trigger: titleRef2.current, // Trigger animation when the title is in view
          start: "top 80%", // Start animation when top of title is at 80% of the viewport height
          end: "top 20%", // End when top of title reaches 30% of the viewport height
          scrub: true, // Optional: Smooth scroll effect
        },
      });
    }
  }, []);

  useLayoutEffect(() => {
    // Check if URL contains #thankyou
    if (window.location.hash === "#thankyou") {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    const handleImageLoad = () => {
      ScrollTrigger.refresh(); // Refresh triggers when images are loaded
    };

    // Select only `.herobg` and `.secbg` images
    const images = document.querySelectorAll(".herobg, .secbg");

    images.forEach((img) => img.addEventListener("load", handleImageLoad));

    return () => {
      images.forEach((img) => img.removeEventListener("load", handleImageLoad));
    };
  }, []);

  const handleClose = () => {
    setShowModal(false);
    // Redirect to base URL
    window.location.href = "https://kronalabs.io/";
  };

  const handleOutsideClick = (e) => {
    if (e.target.className.includes("modal-backdrop")) {
      handleClose();
    }
  };

  useLayoutEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault(); // Prevent the default scroll behavior

      // Adjust scroll speed (change this multiplier to control speed)
      const scrollSpeed = 0.4; // Change this number to increase/decrease scroll speed
      const scrollAmount = event.deltaY * scrollSpeed;

      // Scroll the <html> or <body> element by the adjusted amount
      document.documentElement.scrollBy(0, scrollAmount); // For <html>
      // document.body.scrollBy(0, scrollAmount); // For <body>
    };

    // Attach the wheel event listener to the <html> or <body> element
    document.documentElement.addEventListener("wheel", handleWheel, {
      passive: false,
    });
    // document.body.addEventListener('wheel', handleWheel, { passive: false }); // Or for <body>

    // Clean up on component unmount
    return () => {
      document.documentElement.removeEventListener("wheel", handleWheel);
      // document.body.removeEventListener('wheel', handleWheel); // Clean up for <body>
    };
  }, []);

  useLayoutEffect(() => {
    const getScrollThreshold = () => {
      const width = window.innerWidth;

      // Define thresholds for different viewport widths
      if (width >= 1440) return 750; // Default
      if (width >= 992) return 550; // For 1440px and above
      if (width >= 768) return 450; // For 992px to 1439px
      if (width >= 600) return 400; // For 768px to 991px
      return 32323; // For below 600px
    };

    const handleScroll = () => {
      const scrollThreshold = getScrollThreshold();

      if (window.scrollY > scrollThreshold) {
        setShowToggle(false); // Hide the toggle
      } else {
        setShowToggle(true); // Show the toggle
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to run only on mount

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      // Animate heading
      gsap.from(".hero-heading", {
        y: 50,
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        stagger: 0.2, // Animates each line sequentially
      });

      // Animate paragraph
      gsap.from(".hero-paragraph", {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: 0.5,
        ease: "power3.out",
      });
    }, heroRef);

    return () => ctx.revert(); // Cleanup animation context
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      // Animate the paragraph
      gsap.fromTo(
        ".animated-paragraph",
        {
          opacity: 0,
          y: 20, // Start slightly below
        },
        {
          opacity: 1,
          y: 0, // Move to its original position
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".animated-paragraph",
            start: "top 85%", // Trigger when top of element is 60% from viewport top
            end: "top 20%",
            scrub: 1,
          },
        }
      );

      // Animate the button
      gsap.fromTo(
        ".animated-button",
        {
          opacity: 0,
          scale: 0.9, // Slightly smaller at the start
        },
        {
          opacity: 1,
          scale: 1, // Scale back to normal
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".animated-button",
            start: "top 80%", // Trigger when the top of button is 70% from the viewport top
            end: "top 20%",
            scrub: 1,
          },
        }
      );
    }, rightColRef);

    return () => ctx.revert(); // Cleanup GSAP context
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      // Animate "Our team,"
      gsap.fromTo(
        ".line1",
        {
          marginLeft: "-20px", // Initial margin-left
          opacity: 0, // Fully transparent
        },
        {
          marginLeft: "0px", // Final margin-left
          opacity: 1, // Fully visible
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".line1",
            start: "top 85%",
            end: "top 40%",
            scrub: 1,
          },
        }
      );

      // Animate "your project,"
      gsap.fromTo(
        ".line2",
        {
          marginLeft: "-20px",
          opacity: 0,
        },
        {
          marginLeft: "0px",
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".line2",
            start: "top 85%",
            end: "top 40%",
            scrub: 1,
          },
        }
      );

      // Animate "your goals."
      gsap.fromTo(
        ".line3",
        {
          marginLeft: "-20px",
          opacity: 0,
        },
        {
          marginLeft: "0px",
          opacity: 1,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".line3",
            start: "top 85%",
            end: "top 40%",
            scrub: 1,
          },
        }
      );
    }, teamRef);

    return () => ctx.revert(); // Cleanup GSAP context
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      const section = document.querySelector(".section");

      if (!section) return;

      const isMobile = window.innerWidth <= 768; // Define mobile breakpoint

      const scrollSettings = {
        trigger: section,
        pin: true,
        scrub: 0.3,
        snap: 1 / (boxes.length - 1),
        end: () => "+=" + section.offsetWidth,
      };

      const mobileScrollSettings = {
        trigger: section,
        pin: true,
        scrub: 0.3,
        snap: 1 / (boxes.length - 1),
        start: "top+=0 top", // Adjusted start for mobile
        end: () => "+=" + section.offsetWidth * 10, // Dynamic end value
      };

      if (isMobile) {
        gsap.to(boxes, {
          xPercent: -100 * (boxes.length + 0.5),
          ease: "none",
          scrollTrigger: mobileScrollSettings,
        });
      } else {
        gsap.to(boxes, {
          xPercent: -100 * (boxes.length - 1),
          ease: "none",
          scrollTrigger: scrollSettings,
        });
      }
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      {showModal && (
        <div className="modal-backdrop" onClick={handleOutsideClick}>
          <div className="modal-content">
            <button className="close-btn" onClick={handleClose}>
              &times;
            </button>
            <h2>Thank You!</h2>
            <p>Your message has been received.</p>
          </div>
        </div>
      )}
      {showToggle && (
        <header className={`header ${isSticky ? "stickynav" : ""}`}>
          <Navbar />
        </header>
      )}
      <div className="hero-sec">
        <div className="hero-content" ref={heroRef}>
          <h1 className="hero-heading">
            Save Time, <br />
            Scale Faster.
          </h1>
          <p className="hero-paragraph">
            Proven Expertise in Building <br /> and Scaling Web3 Ventures.{" "}
            <a href="http://calendly.com/kronalabs" className="phonelearn">
              <br />
              <button className="solid-btn">Let's Talk</button>
            </a>
          </p>
        </div>
        <img src={herobg} className="herobg phonehidden" alt="" />
        <img src={secbgphone} className="herobg showphone" alt="" />
      </div>

      <div className="two-col-sec" id="about">
        <div className="sec-box">
          <div className="row items-start">
            <div className="col" ref={teamRef}>
              <h2>
                <span className="line1">Our team,</span> <br />
                <span className="line2">your project,</span> <br />
                <span className="line3">your goals.</span>
              </h2>
            </div>
            <div className="col" ref={rightColRef}>
              <p className="animated-paragraph">
                We fast-track Web3 ventures through expertise, strategic
                partnerships, and seamless execution—empowering your team to
                grow faster and smarter.
              </p>
              <a href="http://calendly.com/kronalabs">
                {" "}
                <button className="solid-btn animated-button">
                  Let's Talk
                </button>
              </a>
            </div>
          </div>
        </div>
        <img src={secbg} className="secbg phonehidden" alt="" />
        <img src={secimgphone} className="secbg showphone" alt="" />
      </div>

      <section className="values-section">
        <TitleAnimation text="Our Values" class="sec-title" />
        <div className="sec-box">
          <Swiper
            spaceBetween={0}
            onSwiper={(swiperInstance) => (swiperRef.current = swiperInstance)}
            slidesPerView={3}
            centeredSlides={true}
            breakpoints={{
              320: { slidesPerView: 1 }, // Mobile
              768: { slidesPerView: 2 }, // Tablet
              1024: { slidesPerView: 3 }, // Desktop
            }}
            loop={true}
            grabCursor={true}
          >
            <SwiperSlide>
              <div className="value-box">
                <img src={bag} alt="Bag" />
                <h3>Impact-Driven Solutions</h3>
                <p>Focused on delivering measurable, lasting results.</p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="value-box">
                <img src={bag} alt="Bag" />
                <h3>Experience-Backed Guidance</h3>
                <p>We've built and scaled projects to market success.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="value-box">
                <img src={bag} alt="Bag" />
                <h3>Strategic Connections</h3>
                <p>
                  Unlock opportunities through our network of trusted partners.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="value-box">
                <img src={bag} alt="Bag" />
                <h3>Efficiency & Commitment</h3>
                <p>
                  Optimize your journey save time and fast success by leverage
                  our expertise and commitment to your project
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="value-box">
                <img src={bag} alt="Bag" />
                <h3>Transparency and Communication</h3>
                <p>
                  Unlock opportunities through our network of trusted partners.
                </p>
              </div>
            </SwiperSlide>

            <div className="slider-controls">
              <div onClick={handlePrev} class="swiper-button-prev"></div>
              <div onClick={handleNext} class="swiper-button-next"></div>
            </div>
          </Swiper>
        </div>
      </section>

      <section className="section" id="services">
        <TitleAnimation text="What We Do" class="sec-title" />

        <div className="img-mask">
          <div className="box">
            <h3>Go-To-Market Execution & Partnerships</h3>
            <img src={icon1} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Execute launch strategies that create early traction and lasting
                momentum.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Branding & Design</h3>
            <img src={brandingicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Establish your brand identity with striking visuals and
                compelling storytelling.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Strategic Planning & Market Positioning</h3>
            <img src={strategicicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Develop strategies that align with market trends and your
                vision.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Web Development & dApp Integration</h3>
            <img src={globeicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Build user-centric platforms designed for engagement and
                retention.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Token Ecosystem Design & Compliance</h3>
            <img src={tokenicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>Design sustainable token models with regulatory guidance.</h4>
            </div>
          </div>
          <div className="box">
            <h3>Community Growth & KOL Campaigns</h3>
            <img src={commicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Grow vibrant communities and amplify visibility through targeted
                campaigns.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Exchange Listings & Growth Infrastructure</h3>
            <img src={listingicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Secure listings on top exchanges to boost liquidity and
                visibility.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Project Advisory</h3>
            <img src={projecticon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Provide expert guidance to navigate challenges and align
                strategies with your vision.
              </h4>
            </div>
          </div>
          <div className="box">
            <h3>Social Media Strategy</h3>
            <img src={phoneicon} alt="" />
            <div className="plus-icon">
              <span></span>
              <span></span>
            </div>
            <div className="box-hidden-content">
              <h4>
                Use our proven strategies to enhance engagement, build loyalty,
                and grow your audience.
              </h4>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section-phone showphone" id="servicesp">
      <TitleAnimation text="What We Do" class="sec-title" />
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          breakpoints={{
            600: {
              slidesPerView: 1,
            },
          }}
        >
        
          <SwiperSlide>
            <div className="box-phone">
              <h3>Go-To-Market Execution & Partnerships</h3>
              <img src={icon1} alt="Icon 1" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Execute launch strategies that create early traction and
                  lasting momentum.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Branding & Design</h3>
              <img src={brandingicon} alt="Branding Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Establish your brand identity with striking visuals and
                  compelling storytelling.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Strategic Planning & Market Positioning</h3>
              <img src={strategicicon} alt="Strategic Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Develop strategies that align with market trends and your
                  vision.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Web Development & dApp Integration</h3>
              <img src={globeicon} alt="Web Development Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Build user-centric platforms designed for engagement and
                  retention.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Token Ecosystem Design & Compliance</h3>
              <img src={tokenicon} alt="Token Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Design sustainable token models with regulatory guidance.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Community Growth & KOL Campaigns</h3>
              <img src={commicon} alt="Community Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Grow vibrant communities and amplify visibility through
                  targeted campaigns.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Exchange Listings & Growth Infrastructure</h3>
              <img src={listingicon} alt="Listing Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Secure listings on top exchanges to boost liquidity and
                  visibility.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Project Advisory</h3>
              <img src={projecticon} alt="Project Advisory Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Provide expert guidance to navigate challenges and align
                  strategies with your vision.
                </h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="box-phone">
              <h3>Social Media Strategy</h3>
              <img src={phoneicon} alt="Phone Icon" />
              <div className="plus-icon">
                <span></span>
                <span></span>
              </div>
              <div className="box-hidden-content">
                <h4>
                  Use our proven strategies to enhance engagement, build
                  loyalty, and grow your audience.
                </h4>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section> */}

      <section className="sticky-boxes-section">
        <TitleAnimation text="Why Krona Labs" class="sec-title" />

        <div className="sec-box">
          <StickyBox />
        </div>
      </section>
      <section className="partners-section ">
        <TitleAnimation text="Partners" class="sec-title phonehidden" />
        <h2 className="sec-title fadein desktophidden" ref={titleRef2}>
          Partners
        </h2>
        <Marquee />
      </section>
      <section className="get-in-touch-section" id="contact">
        <TitleAnimation text="Get in Touch." class="sec-title phonehidden" />
        <h2 className="sec-title  fadein showphone" ref={titleRef2}>
          Get in Touch.
        </h2>
        <p>Let’s Build Something Amazing Together.</p>
        <form method="post" action="https://kronalabs.io">
          <div className="form-col-2">
            <input type="text" name="name" placeholder="Name*" />
            <input type="text" name="email" placeholder="Email*" />
          </div>
          <div className="form-col-1">
            <input
              type="text"
              name="message"
              placeholder="Message"
              className="textarea"
            />
          </div>
          <div className="form-col-between">
            <a href="mailto:biz@kronalabs.com">biz@kronalabs.com</a>
            <button className="solid-btn">Submit</button>
          </div>
        </form>
      </section>
      <Footer />
    </div>
  );
}
